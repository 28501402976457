$tile-border-color: #DBEFFD;
$tile-color: #EBF6FE;
$tile-disabled-border-color: #F2F2F2;
$tile-disabled-color: #F9F9F9;
$dark-blue: #0C324D;

.ServicesGrid {
  & {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ServicesGridElementWrapper {
    & {
      padding:10px;
    }

    .ServicesGridElement {
      & {
        padding: 30px 10px 10px;
        background-color: $tile-color;
        box-shadow: 0px 11px 18px 0px #F3F9FD;
        border: 1px solid $tile-border-color;
        cursor: pointer;
      }

      &:hover {
        background-color: $tile-border-color;
      }

      p {
        font-family: Inter;
        font-size: 13px;
        font-weight: 600;
        line-height: 21px;
        height: 42px;
        letter-spacing: 0em;
        text-align: center;
        color:$dark-blue;
      }

      .ServicesGridElementIconwrapper {
        & {
          display: inline-flex;
          height: 64px;
          max-height: 64px;
          width: 100%;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }

        img {
          height: 100%;
        }
      }

    }

    .ServicesGridElement.ServicesGridElementDisabled {
      &, &:hover {
        background-color: $tile-disabled-color;
        border: 1px solid $tile-disabled-border-color;
        cursor: initial;
      }

      p {
        color: #B9B9B9;
      }
    }
  }
}