@font-face {
  font-family: "Inter";   /*Can be any text*/
  src: local("Inter"),
  url("./fonts/inter.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:  rgba(0,0,0,0.4) url("img/background.png");
  background-blend-mode: multiply;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  transition: all 0.3s;
  font-family: Inter, serif;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width:100vw;
  height: 100vh;
}

.AppWrapper {
  width:1340px;
  max-width: 90vw;
  height: 800px;
  max-height: 90vh;
  background: #fff;
  border-radius: 10px;
  padding: 0;
  overflow-y: auto;
}

.SpinnerContainer {
  background: rgba(0,0,0,0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.SpinnerContainer img{
  margin-bottom: 40px;
}

a {
  text-decoration: none;
  color: inherit;
}

